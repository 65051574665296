import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import * as Chakra from "@chakra-ui/react";

/*
	This component creates a page transition based on the browser location changing.
 */

interface Props extends Chakra.FlexProps {
  variant: any;
}

const HeaderTransition: React.FC<Props> = ({ variant, ...props }) => {
  const [isFirstHydratedRender, setIsFirstHydratedRender] = React.useState(false);

  let x = Chakra.useBreakpoint('base')
  let isUnderDesktop = x === 'base' || x === 'sm' || x === 'md' || x === 'lg'

  React.useEffect(() => {
    setIsFirstHydratedRender(true);
  }, []);

  const duration = 0.2;
  const variants = {
    initial: {
      opacity: isFirstHydratedRender ? 0 : 1,
      transition: { duration },
      position: "absolute",
      width: "100%",
      overflowY: "hidden",
      translateY: isFirstHydratedRender ? isUnderDesktop ? "0" : "-10px" : "0"
    },
    enter: {
      opacity: 1,
      transition: {
        duration,
        delay: duration,
        when: "beforeChildren",
      },
      width: "100%",
      overflowY: "initial",
      position: "relative",
      translateY: "0px"
    },
    exit: {
      opacity: 0,
      transition: { duration },
      width: "100%",
      overflowY: "hidden",
      translateY: isUnderDesktop ? "0" : "10px"
    },
  };

  const windowDef = typeof window !== "undefined";

  return (
      <AnimatePresence>
        <Chakra.Flex
            pr={{ base: '25px', xl: '100px' }}
            as={motion.div}
            w="100%"
            h="100%"
            maxWidth={{ xl: '800px' }}
            justifyContent="flex-end"
            key={variant ? variant : "key"}
            variants={variants}
            initial="initial"
            animate="enter"
            exit={windowDef && "exit"}
            {...props}
        />
      </AnimatePresence>
  );
};

export default HeaderTransition;
