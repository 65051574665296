import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

const breakpoints = createBreakpoints({
	sm: '576px',
	md: '768px',
	lg: '992px',
	xl: '1200px',
	'2xl': '1400px'
})

export const themeColors = {
	white: '#fff',
	black: '#000',
	orange: '#EA5F1E',
	red: '#CE3E1F',
	lightRed: '#ED2323',
	blue: '#2F62A6',
	green: '#5EA562',
	yellow: '#FFA82F',
	bone: '#F6F2ED',
	transparent: 'transparent',
	grey: {
		50: '#FAFAFA',
		100: '#282C32',
		200: '#E1E1E1',
		300: '#848484',
		400: '#292C31',
		500: '#EDEDED',
		600: '#4A4A4a',
		700: '#DFDFDF',
		800: '#666666',
	},
	brown: '#ECE0CF',
	dark: {
		yellow: '#e6972a',
		blue: '#2a5895',
		orange: '#d3561b',
		red: '#b9381c',
		green: '#559558'
	},
	darker: {
		yellow: '#cc8626',
		blue: '#264e85',
		orange: '#bb4c18',
		red: '#a53219',
		green: '#4b844e'
	}
}

const theme = extendTheme({
	styles: {
		global: {
			html: {
				overflowY: "scroll"
			}
		}
	},
	fonts: {
		heading: `'Formular', sans-serif`,
		body: `'Formular', sans-serif`
	},
	colors: themeColors,
	breakpoints
})

export default theme

