import React from "react";
import * as Chakra from "@chakra-ui/react";

const ColorBubbles = ({ active, ...props }) => {
  const keyframes = Chakra.keyframes`
		0% {
			transform: translateY(0px);
		}
		13% {
			transform: translateY(-8px);
		}
		27% {
			transform: translateY(0px);
		}
		100% {
			transform: translateY(0px);
		}
	`;

  return (
    <Chakra.Stack justify="center" direction="row" spacing="20px" {...props}>
      <Chakra.Box
        animation={active ? `${keyframes} 2s infinite 0ms` : ``}
        borderRadius="full"
        boxSize="20px"
        bg="red"
      />
      <Chakra.Box
        animation={active ? `${keyframes} 2s infinite 50ms` : ``}
        borderRadius="full"
        boxSize="20px"
        bg="orange"
      />
      <Chakra.Box
        animation={active ? `${keyframes} 2s infinite 100ms` : ``}
        borderRadius="full"
        boxSize="20px"
        bg="yellow"
      />
      <Chakra.Box
        animation={active ? `${keyframes} 2s infinite 150ms` : ``}
        borderRadius="full"
        boxSize="20px"
        bg="green"
      />
      <Chakra.Box
        animation={active ? `${keyframes} 2s infinite 200ms` : ``}
        borderRadius="full"
        boxSize="20px"
        bg="blue"
      />
    </Chakra.Stack>
  );
};

export default ColorBubbles;
