/*
* dnep = Do not execute if PressLess
*
* 2nd arg can be used to run alternative code if the client IS PressLess
*
*  */


import React from "react";
import * as Chakra from "@chakra-ui/react"

export default function dnep(code: (values?: any) => void, alternativeCode?: (values: any) => void) {

    // Return original function if SSR or if window exists but not in an iframe
    if (typeof window === "undefined" || window.self === window.top) {
        return code
    }

    if (alternativeCode) {
        return alternativeCode
    }

    return () => {}
}

export const isPressLess = () => typeof window !== "undefined" && window.self !== window.top

export const useIsSsr = () => {
    const [isSsr, setIsSsr] = React.useState(true)
    React.useEffect(() => setIsSsr(false), [])
    return isSsr
}

export const useIsPressLess = () => {

    const isSsr = useIsSsr()

    if (!isSsr && isPressLess()) {
        return true
    }

    return false
}

export const PressLessOnly = ({children}: {children: React.ReactElement}) => {

   const isSsr = useIsSsr()

    if (!isSsr && isPressLess()) {
        return children
    }

    return null
}

export const ClientOnly = ({children}: {children: React.ReactElement}) => {

    const isSsr = useIsSsr()

    if (isSsr || !isPressLess()) {
        return children
    }

    return null

}


export const PressLessMessageBox = ({children}: {children: any}) => {

    const [hide, setHide] = React.useState(false)

    return (
        <PressLessOnly>
            {!hide && <Chakra.Box p="20px">
                <Chakra.Alert status='info' borderRadius="20px" shadow="md" color="white">
                    <Chakra.AlertIcon />
                    <Chakra.Box flex='1'>
                        <Chakra.AlertDescription display='block'>
                            {children}
                        </Chakra.AlertDescription>
                        <Chakra.AlertDescription display='block' fontSize="12px">
                            This message box only appears within the Editor - to help you craft your site and product. It will not appear to your end users.
                        </Chakra.AlertDescription>
                    </Chakra.Box>
                    <Chakra.CloseButton position='absolute' right='8px' top='8px' onClick={()=>setHide(true)} />
                </Chakra.Alert>
            </Chakra.Box>}
        </PressLessOnly>
    )
}
