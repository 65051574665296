import React from 'react'
import * as Chakra from '@chakra-ui/react'
import ColorBubbles from '../color-bubbles'
import Heading from '../heading'
import DividerText from '../divider-text'
import FormInput from '../inputs/form-input'
import BrownButton from '../brown-button'
import { Link } from 'gatsby'
import { GET_USER } from "../../hooks/use-auth"
import { gql, useMutation } from "@apollo/client"
import { navigate } from "gatsby"
import {LoginViaSocials} from '../../utils/social-logins'
import dnep, {PressLessMessageBox} from "../../utils/dnep";
import {useLocation} from "@reach/router";


export const LOG_IN = gql`
  mutation logIn($login: String!, $password: String!) {
    loginWithCookies(input: { login: $login, password: $password }) {
      status
    }
  }
`

const LoginForm: PressLessComponent<emptyPresslessAttributes> = React.forwardRef<HTMLDivElement, emptyPresslessAttributes>((props, ref) => {


  const [logIn, { loading, error }] = useMutation(LOG_IN, {
		refetchQueries: [{ query: GET_USER }],
	})

	const { pathname } = useLocation()


	const errorMessage = error?.message || ""
	const isEmailValid =
	  !errorMessage.includes("empty_email") &&
	  !errorMessage.includes("empty_username") &&
	  !errorMessage.includes("invalid_email") &&
	  !errorMessage.includes("invalid_username")
	const isPasswordValid =
	  !errorMessage.includes("empty_password") &&
	  !errorMessage.includes("incorrect_password")

	const handleSubmit = dnep((event) => {

	  event.preventDefault()
	  const data = new FormData(event.currentTarget)
	  const { email, password } = Object.fromEntries(data)
	  logIn({
		variables: {
		  login: email,
		  password,
		},
	  })
		.then(() => {
			if (pathname.includes("login")) {
				navigate("/portal")
			}
		})
		.catch((error) => {
		  console.error(error)
		})
	})

	return (
		<Chakra.Flex ref={ref} direction="column" width="100%" flexGrow="1">
		<Chakra.Flex justify="center" bg="bone" flexGrow="1" height="100%" mt="130px" {...props}>
			<PressLessMessageBox>
				This login form is disabled in the Editor.
			</PressLessMessageBox>
			<Chakra.Flex w="100%" maxW="805px" justify="center" direction="column">
				<ColorBubbles mb="42px" active={loading} />
				<Heading textAlign="center" mb="72px">
					Log in to BaccPrep
				</Heading>
				<Chakra.Flex
					direction={{ base: 'column', xl: 'row' }}
					mb="29px"
					justify="space-between"
					w="100%"
					px="25px"
				>
					<Chakra.VStack
					spacing="25px"
					alignItems="center"
					justifyContent="center"
					w="100%"
					direction="column"
					py="20px"
					as="form"
					onSubmit={handleSubmit}
					>
						<FormInput
						placeholder="Email Address"
                        name="email"
						type="email"
						isRequired
						/>
						 {!isEmailValid ? (
							<p className="error-message">
								Invalid Email
							</p>
							) : null}
						<FormInput
						placeholder="Password"
						type="password"
                        name="password"
						isRequired
						/>
						{!isPasswordValid ? (
						<p className="error-message">
							Invalid Password
						</p>
						) : null}
						<BrownButton w="100%" type="submit">{loading ? <Chakra.Spinner size="sm" color="grey.100"/> : "Login"}</BrownButton>
					</Chakra.VStack>
					{/*<Chakra.Flex direction={{ base: 'column', xl: 'row' }}>
						<DividerText
							d={{ base: 'none', xl: 'flex' }}
							orientation="vertical"
							px="38px"
						>
							or
						</DividerText>
						<DividerText d={{ base: 'flex', xl: 'none' }} my="52px">
							or
						</DividerText>
					</Chakra.Flex>
					<LoginViaSocials/>*/}
				</Chakra.Flex>
				<Chakra.Flex direction="column" justify="center">
					<Chakra.Link
						alignSelf="center"
						as={Link}
						textDecor="underline"
						to="/forgot-password"
						fontSize="13px"
						color="grey.600"
					>
						Forgot your password?
					</Chakra.Link>
					<Chakra.Text mb="130px" mt="20px" fontSize="11px" textAlign="center">
						All rights reserved {(new Date()).getFullYear()}, BaccPrep
						<br />
						<Chakra.Link as={Link} textDecor="underline" to="/tos">
							Terms & Conditions
						</Chakra.Link>
					</Chakra.Text>
				</Chakra.Flex>
			</Chakra.Flex>
		</Chakra.Flex>
		</Chakra.Flex>
	)
})

LoginForm.pressless = {
	name: "Login Form",
	attributes: {

	}
}

export default LoginForm
