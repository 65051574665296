import React from 'react'
import * as Chakra from '@chakra-ui/react'

const Heading = ({ children, ...props })=> {
	return (
		<Chakra.Heading
			fontWeight="500"
			lineHeight={{ base: '34px', xl: '48px' }}
			fontSize={{ base: '24px', xl: '34px' }}
			{...props}
		>
          {children}
		</Chakra.Heading>
	)
}

export default Heading
