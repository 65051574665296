import React from 'react'
import * as Chakra from '@chakra-ui/react'

const colors = ['red', 'orange', 'yellow', 'green', 'blue']

const StepBubbles = ({ current, ...props }) => {


	return (
		<Chakra.Stack direction="row" {...props}>
			{[...Array(colors.length)].map((e, i) => (
				<Chakra.Box
					boxSize="8px"
					bg={i <= current ? colors[i] : 'grey.300'}
					opacity={i <= current ? 1 : 0.25}
					key={i}
					borderRadius="full"
					spacing="8px"
				></Chakra.Box>
			))}
		</Chakra.Stack>
	)
}

export default StepBubbles
