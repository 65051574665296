import React from "react";
import * as Chakra from "@chakra-ui/react";
import HeroMenuSubject from "./hero-menu-subject";
import PlusIcon from "../images/plus-icon.svg";
import { gsap } from "../gsap/src/index";
import { Flip } from "../gsap/src/Flip";
import SimpleCrossIcon from "../images/simple-cross-icon.svg";
import { graphql, useStaticQuery } from "gatsby";

if (typeof window !== "undefined") {
  gsap.registerPlugin(Flip);
}

const HeroMenu = (props) => {
  const { allWpSubject } = useStaticQuery(graphql`
    {
      allWpSubject {
        nodes {
          id
          subjectMeta {
            iconColor
            iconTextColor
            linkedMarketingPage {
              ... on WpPage {
                id
                link
              }
            }
          }
          link
          name
          description
        }
      }
    }
  `);
  const { isOpen, onOpen, onClose } = Chakra.useDisclosure();

  let x = Chakra.useBreakpoint("base");
  let isUnderDesktop = () =>
    x === "base" || x === "sm" || x === "md" || x === "lg";

  const boxRef = React.useRef();
  const openButton = React.useRef();
  const exitRef = React.useRef();
  const outerBoxRef = React.useRef();

  React.useEffect(() => {
    if (!isUnderDesktop()) {
      onClose();
      boxRef.current.classList.remove("visible");
      openButton.current.classList.remove("hidden");
    }
  }, [x]);

  const doOpen = () => {
    if (isUnderDesktop()) {
      const state1 = Flip.getState([openButton.current], {
        props: "backgroundColor, color, borderRadius, opacity",
      });
      openButton.current.classList.add("hidden");
      if (isOpen) {
      } else {
        //openButton.current.classList.remove("hidden")
        //boxRef.current.classList.remove("visible")
      }
      Flip.from(state1, {
        ease: "power1.inOut",
        duration: 0.2,
        absolute: true,
        onComplete: () => {
          onOpen();
          const state2 = Flip.getState([boxRef.current, exitRef.current], {
            props: "backgroundColor, color, opacity",
          });
          boxRef.current.classList.add("visible");
          Flip.from(state2, {
            ease: "power1.inOut",
            duration: 0.4,
            absolute: true,
          });
        },
      });
    }
  };

  const doClose = () => {
    if (isUnderDesktop()) {
      onClose();
      const state2 = Flip.getState([outerBoxRef.current]);
      gsap.to(boxRef.current, {
        opacity: 0,
        duration: 0.1,
        onComplete: () => {
          boxRef.current.classList.remove("visible");
          Flip.from(state2, {
            ease: "power1.inOut",
            duration: 0.4,
            absolute: true,
            onComplete: () => {
              const state1 = Flip.getState([openButton.current], {
                props: "backgroundColor, color, borderRadius, opacity",
              });
              openButton.current.classList.remove("hidden");
              Flip.from(state1, {
                ease: "power1.inOut",
                duration: 0.2,
                absolute: true,
              });
              gsap.to(boxRef.current, { opacity: 1, duration: 0.5 });
            },
          });
        },
      });
    }
  };

  return (
    <Chakra.Flex
      boxShadow="0px 2px 8px rgba(0, 0, 0, 0.1)"
      borderRadius="9px"
      pos="absolute"
      left={{ base: "25px", xl: "110px" }}
      bottom="165px"
      flex="1"
      bg="white"
      justify="space-between"
      alignItems="center"
      fontWeight="500"
      w={{ base: `calc(100% - ${50}px)`, xl: `calc(100% - ${220}px)` }}
      {...props}
      ref={outerBoxRef}
    >
      <Chakra.Flex
        ref={openButton}
        right="0"
        position="absolute"
        opacity="1"
        d={{ base: "block", xl: "none" }}
        css={{ "&.hidden": { opacity: "0" } }}
        fontSize="14px"
        onClick={doOpen}
        flexShrink={0}
        zIndex="2"
      >
        <Chakra.Box pr="20px" flexShrink={0}>
          <Chakra.Box as="span" mr="16px">
            Explore
          </Chakra.Box>
          <PlusIcon />
        </Chakra.Box>
      </Chakra.Flex>

      <Chakra.Flex
        borderRadius="9px"
        overflow={{ base: "hidden", xl: "unset" }}
        flex="1"
        flexDirection="row"
        css={{ "&.visible": { flexDirection: "column" } }}
        ref={boxRef}
      >
        <Chakra.Box
          onClick={isOpen ? doClose : undefined}
          ref={exitRef}
          position="absolute"
          top="-28px"
          right="2px"
          opacity={isOpen ? 1 : 0}
          transition="0.5s"
        >
          <SimpleCrossIcon />
        </Chakra.Box>
        {allWpSubject.nodes.map((subject, i) => (
          <HeroMenuSubject
              key={i}
            isOpen={isOpen}
            bgColor={subject.subjectMeta.iconColor}
            color="#fff"
            to={
              subject?.subjectMeta?.linkedMarketingPage &&
              subject?.subjectMeta?.linkedMarketingPage?.link
            }
            text={subject.name}
            description={subject.description}
          />
        ))}
      </Chakra.Flex>
    </Chakra.Flex>
  );
};

export default HeroMenu;
