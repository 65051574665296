import {GlobalState} from "react-gstate";

interface SocialRegistration {
    provider: string
    token: string
}

interface TraditionalRegistration {
    firstName?: string
    lastName?: string
    emailAddress?: string
    password?: string
}

interface RegistrationUnion {
    type: "TRADITIONAL" | "SOCIAL"
    data: TraditionalRegistration | SocialRegistration
}

interface SignupFormStateMachineState {
    accountLoginData?: RegistrationUnion
    agreeToTerms?: boolean
    ibSchool?: string
    ibMemberType?: "TEACHER" | "STUDENT"
    ibFinishDateMonth?: string
    ibFinishDateYear?: string
    planId?: string
    subjects: string[]
    currentStep: number
    previousStep: number
}

class SignupFormStateMachine extends GlobalState<SignupFormStateMachineState> {

    navigate = (step: number) => {
        SignupFormState.setState({currentStep: step, previousStep: SignupFormState.state.currentStep})
    }

    navigateNext = () => {
        SignupFormState.setState({currentStep: SignupFormState.state.currentStep + 1, previousStep: SignupFormState.state.currentStep})
    }

    navigatePrev = () => {
        if (SignupFormState.state.previousStep < SignupFormState.state.currentStep) {
            SignupFormState.setState({currentStep: SignupFormState.state.previousStep, previousStep: SignupFormState.state.currentStep})
        } else {
            SignupFormState.setState({currentStep: SignupFormState.state.currentStep - 1, previousStep: SignupFormState.state.currentStep})
        }
    }

}

const SignupFormState = new SignupFormStateMachine({currentStep: 0, previousStep: 0, subjects: []})

export default SignupFormState
