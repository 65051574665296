exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-portal-js": () => import("./../../../src/pages/portal.js" /* webpackChunkName: "component---src-pages-portal-js" */),
  "component---src-templates-wp-assessment-js": () => import("./../../../src/templates/WpAssessment.js" /* webpackChunkName: "component---src-templates-wp-assessment-js" */),
  "component---src-templates-wp-assessments-js": () => import("./../../../src/templates/WpAssessments.js" /* webpackChunkName: "component---src-templates-wp-assessments-js" */),
  "component---src-templates-wp-course-js": () => import("./../../../src/templates/WpCourse.js" /* webpackChunkName: "component---src-templates-wp-course-js" */),
  "component---src-templates-wp-definition-js": () => import("./../../../src/templates/WpDefinition.js" /* webpackChunkName: "component---src-templates-wp-definition-js" */),
  "component---src-templates-wp-example-js": () => import("./../../../src/templates/WpExample.js" /* webpackChunkName: "component---src-templates-wp-example-js" */),
  "component---src-templates-wp-page-js": () => import("./../../../src/templates/WpPage.js" /* webpackChunkName: "component---src-templates-wp-page-js" */),
  "component---src-templates-wp-subject-js": () => import("./../../../src/templates/WpSubject.js" /* webpackChunkName: "component---src-templates-wp-subject-js" */),
  "component---src-templates-wp-topic-js": () => import("./../../../src/templates/WpTopic.js" /* webpackChunkName: "component---src-templates-wp-topic-js" */),
  "component---src-templates-wp-topics-js": () => import("./../../../src/templates/WpTopics.js" /* webpackChunkName: "component---src-templates-wp-topics-js" */),
  "component---src-templates-wp-unit-js": () => import("./../../../src/templates/WpUnit.js" /* webpackChunkName: "component---src-templates-wp-unit-js" */)
}

