import React from "react";
import * as Chakra from "@chakra-ui/react";
import { gsap } from "../gsap/src/index";
import { Flip } from "../gsap/src/Flip";
import { Link } from "gatsby";
import Button from "./button";
if (typeof window !== "undefined") {
  gsap.registerPlugin(Flip);
}

const HeroMenuSubject = ({
  bgColor,
  color,
  text,
  isOpen,
  to,
  description,
  ...props
}) => {
  let x = Chakra.useBreakpoint("base");
  let isUnderDesktop = () =>
    x === "base" || x === "sm" || x === "md" || x === "lg";

  const boxRef = React.useRef();
  const textRef = React.useRef();
  const [visible, setVisible] = React.useState(false);
  const [renderedOnce, setRenderedOnce] = React.useState(false);

  React.useEffect(() => {
    if (typeof window !== "undefined" && renderedOnce) {
      if (!isUnderDesktop()) {
        const state = Flip.getState([boxRef.current], {
          props: "backgroundColor, color, borderRadius",
        });
        if (renderedOnce) {
          if (visible) {
            boxRef.current.classList.add("visible");
          } else {
            boxRef.current.classList.remove("visible");
          }

          Flip.from(state, {
            ease: "power1.inOut",
            duration: 0.4,
            absolute: false,
          });
        }
      }
    }
  }, [visible, x]);

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      setRenderedOnce(true);
    }
  }, []);


  return (
    <Chakra.Flex
      role="subject"
      flex={{ base: "0 1 auto", xl: "1" }}
      _first={{
        xl: {
          ".subject-expander": {
            borderRadius: "9px 9px 0 9px",
          },
        },
      }}
      _last={{
        xl: {
          ".subject-expander": {
            borderRadius: "9px 9px 9px 0",
          },
        },
      }}
      _notLast={{
        borderRight: { base: "none", xl: "1px solid rgba(72, 175, 209, 0.5)" },
        borderBottom: {
          base: isOpen ? "1px solid rgba(72, 175, 209, 0.5)" : "none",
          xl: "none",
        },
      }}
      alignItems="center"
      position="relative"
      onMouseOver={() => setVisible(true)}
      height={{ xl: "54px" }}
      {...props}
    >
      <Chakra.Box
        px={{ base: "0px", xl: "20px" }}
        py="15px"
        w="100%"
        borderRadius={{ xl: "9px 9px 0 0" }}
        backgroundColor="#fff"
        height="54px"
        overflow="hidden"
        className="subject-expander"
        position={{ base: "relative", xl: "absolute" }}
        bottom="0"
        sx={{
          "&.visible": {
            position: "absolute",
            backgroundColor: bgColor,
            bottom: 0,
            height: "auto",
            ".body-text": {
              opacity: 1,
            },
            ".border-box": {
              borderColor: "#123F7DFF",
            },
          },
        }}
        ref={boxRef}
        onMouseLeave={() => setVisible(false)}
        cursor="pointer"
      >
        <Chakra.Box
          position="absolute"
          display={{ base: "none", xl: "initial" }}
          px={{ base: "0px", xl: "10px" }}
          py="7px"
          left="0"
          top="0"
          height="100%"
          width="100%"
        >
          <Chakra.Box position="relative" height="100%" width="100%">
            <Chakra.Box
              position="absolute"
              height="100%"
              width="100%"
              className="border-box"
              transition="0.3s"
              border="1px solid #123F7D00"
              mixBlendMode="soft-light"
            />
          </Chakra.Box>
        </Chakra.Box>
        <Chakra.Flex pl={{ base: "12px", xl: 0 }}>
          <Chakra.Box
            mr={{ xl: "16px" }}
            bg={{ base: bgColor, xl: visible ? color : bgColor }}
            boxSize="24px"
            borderRadius="500px"
            transition="0.3s"
          />
          <Chakra.Box
            ml={{ base: "8px", xl: 0 }}
            transition="0.3s"
            d={{ base: isOpen ? "block" : "none", xl: "block" }}
            fontSize="14px"
            color={{ base: "auto", xl: `${visible === true ? color : '#000'}`}}
          >
            {text}
          </Chakra.Box>
        </Chakra.Flex>
        <Chakra.Box
          display={{ base: "none", xl: "block" }}
          fontSize="14px"
          lineHeight="22px"
          transition="0.3s"
          pt="24px"
          color={color}
          className="body-text"
          opacity="0"
          ref={textRef}
          pb="30px"
        >
          {description}
        </Chakra.Box>
        <Link to={to}>
          <Button
            display={{ base: "none", xl: "block" }}
            textColor={color}
            bg="transparent"
            borderWidth="1px"
            borderColor={color}
          >
            Learn More
          </Button>
        </Link>
      </Chakra.Box>
    </Chakra.Flex>
  );
};

export default HeroMenuSubject;
