import {GlobalState} from "react-gstate";
import React from "react";

class headerStateMachine extends GlobalState {

    setCurrentStep = (num) => {
        headerState.setState({currentStep: num})
    }

    setHeaderParams = ({variant, currentStep}) => {

        variant ? headerState.setState({variant: variant}) : headerState.setState({ variant: null });

        if (currentStep !== null && currentStep !== undefined) {
            headerState.setState({currentStep: currentStep})
        }
    }

}

export const headerState = new headerStateMachine({
    variant: null,
    currentStep: 0
})
