import React from "react";
import * as Chakra from "@chakra-ui/react";
import Logo from "../images/logo.svg";
import HamburgerIcon from "../images/hamburger-icon.svg";
import Button from "./button";
import { graphql, Link, useStaticQuery } from "gatsby";
import { useLocation } from "@reach/router";
import SimpleCrossIcon from "../images/simple-cross-icon.svg";
import HeroMenu from "./hero-menu";
import InstagramIcon from "../images/instagram-icon.svg";
import TwitterIcon from "../images/twitter-icon.svg";
import FacebookIcon from "../images/facebook-icon.svg";
import StepBubbles from "./step-bubbles";
import { headerState } from "./state-machines/header-state";
import HeaderTransition from "./transitions/header-transition";
import useAuth from "../hooks/use-auth";
import SignupFormState from "./app/sign-up/sign-up-state";
import { useIsPressLess } from "../utils/dnep";
import profileImage from '../images/profile-image.png'


const Header = (props) => {
  const {
    wp: {
      siteOptions: {
        headerMenu: { headerCtaButton, headerLinks },
        socialSettings: {
          facebookPageUrl,
          instagramAccountUrl,
          twitterAccountUrl,
        },
      },
    },
  } = useStaticQuery(graphql`
    {
      wp {
        siteOptions {
          headerMenu {
            headerCtaButton {
              target
              title
              url
            }
            headerLinks {
              link {
                target
                title
                url
              }
            }
          }
          socialSettings {
            facebookPageUrl
            instagramAccountUrl
            twitterAccountUrl
          }
        }
      }
    }
  `);

  const isMobile = Chakra.useBreakpointValue({
    base: true,
    xl: false,
  });

  const headerHeight = Chakra.useBreakpointValue({ base: "72px", xl: "90px" });

  const { isOpen, onOpen, onClose } = Chakra.useDisclosure();

  const { variant } = headerState.useGlobalState((gs) => gs);

  const currentStep = SignupFormState.useGlobalState((gs) => gs.currentStep);

  const { loggedIn, user } = useAuth();

  const { pathname } = useLocation();

  const expiry = user?.userAccessExpiry;

  const registration =
    new Date().getTime() < new Date(user?.userAccessExpiry).getTime()
      ? "Renew Membership"
      : "Complete my registration";

  const rightSideItems = () => {
    if (variant === "signup") {
      return (
        <Chakra.Flex
          pl={{ base: "25px", xl: "136px" }}
          w="100%"
          fontSize="13px"
          fontWeight="400"
          justify="space-between"
        >
          <Chakra.Flex align="center">
            Step {currentStep + 1}
            <StepBubbles ml="14px" current={currentStep} />
          </Chakra.Flex>
          <Chakra.Flex align="center">
            {!loggedIn ? (
              <>
                <Chakra.Box d={{ base: "none", xl: "block" }}>
                  Already a member?{" "}
                </Chakra.Box>
                <Button
                  px="15px"
                  h="30px"
                  fontSize="13px"
                  as={Link}
                  to="/login/"
                  ml="8px"
                  border="1px solid"
                  borderColor="grey.100"
                  bg="transparent"
                >
                  Login
                </Button>
              </>
            ) : (
              <Chakra.Box d={{ base: "none", xl: "block" }}>
                Complete your registration
              </Chakra.Box>
            )}
          </Chakra.Flex>
        </Chakra.Flex>
      );
    } else if (loggedIn) {
      return (
        <Chakra.Stack
          fontSize="14px"
          d={{ base: "none", xl: "flex" }}
          spacing="0px"
          direction="row"
          alignItems="center"
        >
          <Chakra.Box pr="18px">Hi, {user?.firstName}</Chakra.Box>
          <Chakra.Box as={Link} to="/my-account" pr="28px" overflow="hidden">
            <Chakra.Image src={profileImage} mr='13px'/>
          </Chakra.Box>
          {user?.userAllowedSubjects?.length > 0 ? (
            <Button
              as={Link}
              to="/portal"
              bg="black"
              color="white"
              borderColor="black"
              transition="background-color border border-color 0.5s"
            >
              My Portal
            </Button>
          ) : (
            <Button
              as={Link}
              to="/sign-up"
              bg="black"
              color="white"
              borderColor="black"
              transition="background-color border border-color 0.5s"
            >
              {registration}
            </Button>
          )}
        </Chakra.Stack>
      );
    } else {
      return (
        <Chakra.Stack
          fontSize="14px"
          d={{ base: "none", xl: "flex" }}
          spacing="50px"
          direction="row"
          alignItems="center"
        >
          {headerLinks.map(({ link }, i) => (
            <Chakra.Flex
              key={i}
              as={Link}
              h="100%"
              pos="relative"
              _hover={{ textDecor: "none" }}
              alignItems="center"
              to={link.url}
              _after={
                pathname === link.url && {
                  content: '""',
                  pos: "absolute",
                  bg: "blue",
                  bottom: 0,
                  width: "100%",
                  maxW: "90px",
                  height: "6px",
                  mx: "auto",
                  left: 0,
                  right: 0,
                  borderRadius: "3px",
                  zIndex: 51,
                }
              }
            >
              {link.title}
            </Chakra.Flex>
          ))}

          <Button
            as={Link}
            to={headerCtaButton.url}
            className="heading-button"
            transition="background-color border border-color 0.5s"
          >
            {headerCtaButton.title}
          </Button>
        </Chakra.Stack>
      );
    }
  };

  const isPl = useIsPressLess();

  return (
    <>
      <Chakra.Flex
        w="100%"
        pos={isPl || pathname.includes("unit/") ? "relative" : "sticky"}
        top="0"
        fontWeight="500"
        justify="center"
        zIndex="20"
        transition="color 0.5s"
        {...props}
      >
        <Chakra.Flex
          w="100%"
          justify="space-between"
          alignItems="center"
          css={{
            "&.scrolled": {
              transform: "translateY(-100%)",
            },
          }}
          transition="transform 0.3s, backdrop-filter 0.3s"
          className="heading"
          boxShadow="base"
          flexDir={{ base: variant === "signup" ? "column" : "row", xl: "row" }}
        >
          <Chakra.Flex
            h={{ base: "72px", xl: "90px" }}
            className="heading-left"
            w="100%"
            justifyContent="flex-end"
            transition="background-color 0.5s"
          >
            <Chakra.Flex
              as={Link}
              to="/"
              alignItems="center"
              pl={{ base: "25px", xl: "100px" }}
              w="100%"
              h="100%"
              maxWidth={{ xl: "800px" }}
              className="logo"
            >
              <Logo />
            </Chakra.Flex>
          </Chakra.Flex>

          <Chakra.Flex
            h={{ base: variant === "signup" ? "62px" : "100%", xl: "90px" }}
            w={{ base: variant === "signup" ? "100%" : "auto", xl: "100%" }}
            className="heading-right"
            justifyContent="flex-start"
            transition="background-color 0.5s"
          >
            <HeaderTransition variant={loggedIn ? loggedIn : variant}>
              {rightSideItems()}
              <Button
                onClick={() => (!isOpen ? onOpen() : onClose())}
                alignSelf="center"
                borderRadius="6px"
                ml="25px"
                h="40px"
                d={{
                  base: variant === "signup" ? "none" : "flex",
                  xl: "none",
                }}
                variant="link"
              >
                {!isOpen ? <HamburgerIcon /> : <SimpleCrossIcon />}
              </Button>
            </HeaderTransition>
          </Chakra.Flex>
        </Chakra.Flex>
        <Chakra.Drawer
          blockScrollOnMount={false}
          onClose={onClose}
          isOpen={isOpen && isMobile}
          size={"full"}
        >
          {loggedIn ? (
            <Chakra.DrawerContent bg="bone" style={{ top: headerHeight }}>
              <Chakra.DrawerBody
                width="100%"
                p="45px 25px 0 25px"
                pos="relative"
              >
                <Chakra.Stack
                  fontSize="14px"
                  d={{ base: "flex", xl: "none" }}
                  spacing="0px"
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                  width="100%"
                >
                  <Chakra.Flex justify="space-between" align="center">
                    <Chakra.Box
                      pr="18px"
                      fontSize="14px"
                      fontWeight="500"
                      fontFamily="Formular"
                    >
                      Hi, {user?.firstName}
                    </Chakra.Box>
                    <Chakra.Box
                      onClick={onClose}
                      as={Link}
                      to="/my-account"
                      pr="28px"
                      overflow="hidden"
                    >
                      <Chakra.Image src={profileImage}/>
                    </Chakra.Box>
                  </Chakra.Flex>

                  {user?.userAllowedSubjects?.length > 0 ? (
                    <Button
                      as={Link}
                      to="/portal"
                      bg="black"
                      onClick={onClose}
                      color="white"
                      borderColor="black"
                      transition="background-color border border-color 0.5s"
                    >
                      My Portal
                    </Button>
                  ) : (
                    <Button
                      as={Link}
                      to="/sign-up"
                      bg="black"
                      onClick={onClose}
                      color="white"
                      borderColor="black"
                      transition="background-color border border-color 0.5s"
                    >
                      Complete my registration
                    </Button>
                  )}
                </Chakra.Stack>
                <Chakra.Flex width="100%" pt="36px" justify="space-between">
                  <Chakra.List
                    spacing="12px"
                    styleType="none"
                    flexDir="column"
                    fontWeight="500"
                    fontSize="14px"
                    width="100%"
                  >
                    <Chakra.ListItem as={Link} to="/my-account">
                      My Account
                    </Chakra.ListItem>
                    {headerLinks.map(({ link }, i) => (
                      <Chakra.ListItem key={i}>
                        <Chakra.Link as={Link} to={link.url} onClick={onClose}>
                          {link.title}
                        </Chakra.Link>
                      </Chakra.ListItem>
                    ))}
                  </Chakra.List>
                </Chakra.Flex>
              </Chakra.DrawerBody>
            </Chakra.DrawerContent>
          ) : (
            <Chakra.DrawerContent bg="bone" style={{ top: headerHeight }}>
              <Chakra.DrawerBody p="45px 25px 0 25px" pos="relative">
                <Chakra.Flex mb="35px" justify="center">
                  <Button
                    as={Link}
                    to="/login/"
                    bg="yellow"
                    mr="24px"
                    onClick={onClose}
                  >
                    Login
                  </Button>
                  <Button
                    as={Link}
                    to={headerCtaButton.url}
                    bg="yellow"
                    onClick={onClose}
                  >
                    {headerCtaButton.title}
                  </Button>
                </Chakra.Flex>
                <Chakra.Flex justify="space-between">
                  <Chakra.UnorderedList
                    spacing="12px"
                    styleType="none"
                    flexDir="column"
                    fontWeight="500"
                    fontSize="14px"
                  >
                    {headerLinks.map(({ link }, i) => (
                      <Chakra.ListItem key={i}>
                        <Chakra.Link as={Link} to={link.url} onClick={onClose}>
                          {link.title}
                        </Chakra.Link>
                      </Chakra.ListItem>
                    ))}
                  </Chakra.UnorderedList>
                  <Chakra.Stack
                    alignSelf="flex-end"
                    alignItems="center"
                    direction="row"
                    spacing="26px"
                  >
                    <Chakra.Link href={instagramAccountUrl}>
                      <InstagramIcon />
                    </Chakra.Link>
                    <Chakra.Link href={twitterAccountUrl}>
                      <TwitterIcon />
                    </Chakra.Link>
                    <Chakra.Link href={facebookPageUrl}>
                      <FacebookIcon />
                    </Chakra.Link>
                  </Chakra.Stack>
                </Chakra.Flex>

                <HeroMenu />
              </Chakra.DrawerBody>
            </Chakra.DrawerContent>
          )}
        </Chakra.Drawer>
      </Chakra.Flex>
    </>
  );
};

export default Header;
