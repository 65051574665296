import React from 'react'
import * as Chakra from '@chakra-ui/react'

const FormInput = React.forwardRef<HTMLInputElement, Chakra.InputProps>((props, ref) => {
	return (
		<Chakra.Input
			ref={ref}
			borderRadius="3px"
			fontSize="14px"
			color="black"
			_placeholder={{ color: 'black' }}
			_hover={{ borderColor: 'black' }}
			borderColor="black"
			{...props}
		/>
	)
})

export default FormInput
