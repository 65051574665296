import React from 'react'
import * as Chakra from '@chakra-ui/react'

const Button = ({ ...props }) => {
	return (
		<Chakra.Button
			px="30px"
			py="10px"
			fontSize="14px"
			borderRadius="18px"
			fontWeight="500"
			height="36px"
			_hover={
				props.bg && !props._hover && {
					bg: `dark.${props.bg}`
				}
			}
			_active={
				props.bg && {
					bg: `darker.${props.bg}`
				}
			}
			{...props}
		/>
	)
}

export default Button
