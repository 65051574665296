import React from 'react'
import * as Chakra from '@chakra-ui/react'
import { Helmet } from 'react-helmet'
import './layout.css'
import 'keen-slider/keen-slider.min.css'
import Header from "../components/header";
import Footer from "../components/footer";
import FadeTransition from "../components/transitions/fade-transition";
import useAuth, {AuthProvider} from "../hooks/use-auth";
import LoginForm from "../components/blocks/login-form";
import {navigate, Link} from 'gatsby'

export default function ({ children, location }) {

	return (
    <AuthProvider>
      <Chakra.Flex minH="100vh" flexDirection="column" bgColor="bone">
        <Helmet>
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#e00000" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
          <title>BaccPrep</title>
        </Helmet>
        <Header />
        <FadeTransition shouldChange={location.pathname}>
          {location.pathname.includes("subject/") || location.pathname.includes("portal") || location.pathname.includes("login") || location.pathname.includes("definitions") || location.pathname.includes("examples") || location.pathname.includes("past-papers") ? (
            <AccountLayout location={location}>{children}</AccountLayout>
          ) : <>{children}</>}
        </FadeTransition>
        <Footer />
      </Chakra.Flex>
    </AuthProvider>
	)
}

/*
*
* */

export const AccountLayout = ({ children }) => {
	const { loggedIn, loading, user } = useAuth()


	React.useEffect(() => {
		if (loggedIn && user.userAllowedSubjects.length < 1) {
			setTimeout(() => {
				navigate("/sign-up")
			}, 700)
		}
	}, [loggedIn, user])

	if (loading) {
		return (
			<Chakra.VStack
				w="100%"
				flexGrow={1}
				alignItems="center"
				justifyContent="center"
				py={10}
			>
				<Chakra.Spinner />
			</Chakra.VStack>
		)
	}

	if (loggedIn && user.userAllowedSubjects.length < 1) {
		return (
			<Chakra.VStack
				w="100%"
				flexGrow={1}
				alignItems="center"
				justifyContent="center"
				py={10}
			>
				<Chakra.Spinner />
				<Chakra.Box textDecoration="underline" as={Link} pt="20px" to="/sign-up">
					Click here if you are not automatically redirected.
				</Chakra.Box>
			</Chakra.VStack>
		)
	}

	if (loggedIn) {
		return children
	}

	if (!loggedIn) {
		return <LoginForm />
	}

	return (<Chakra.VStack
		w="100%"
		flexGrow={1}
		alignItems="center"
		justifyContent="center"
		py={10}
	>
		<Chakra.Box>
			An error has occurred.
		</Chakra.Box>
	</Chakra.VStack>)
}
