import React from 'react'
import * as Chakra from '@chakra-ui/react'

const BrownButton = ({ icon, children, ...props }) => {
	return (
		<Chakra.Flex
			as={Chakra.Button}
			py="11px"
			px="22px"
			justify="space-between"
			bg="rgba(236, 224, 207, 0.75)"
			_hover={{
				bg: `rgba(236, 224, 207, 0.75)`
			}}
			_active={{
				bg: `rgba(212, 202, 186, 0.75)`
			}}
			_disabled={{
				"&:hover": { bg: `rgba(236, 224, 207, 0.75)` }
			}}
			fontWeight="400"
			borderRadius="3px"
			fontSize="14px"
			{...props}
		>
			{icon}
			<Chakra.Box w={!icon && '100%'} textAlign="center">
				{children}
			</Chakra.Box>
			<Chakra.Box />
		</Chakra.Flex>
	)
}

export default BrownButton
