import React from "react";
import * as Chakra from "@chakra-ui/react";
import Logo from "../images/logo.svg";
import InstagramIcon from "../images/instagram-icon.svg";
import TwitterIcon from "../images/twitter-icon.svg";
import FacebookIcon from "../images/facebook-icon.svg";
import Button from "./button";
import { graphql, Link, useStaticQuery } from "gatsby";
import useAuth from "../hooks/use-auth";
import FadeTransition from "./transitions/fade-transition";

const Footer = (props) => {
  const {
    wp: {
      siteOptions: { footerMenu, socialSettings },
    },
  } = useStaticQuery(graphql`
    {
      wp {
        siteOptions {
          socialSettings {
            facebookPageUrl
            instagramAccountUrl
            twitterAccountUrl
          }
          footerMenu {
            buttonLeft {
              target
              title
              url
            }
            buttonRight {
              target
              title
              url
            }
            subMenus {
              fieldGroupName
              links {
                link {
                  target
                  title
                  url
                }
              }
              subMenuTitle
            }
          }
        }
      }
    }
  `);

  const { loggedIn, logout } = useAuth();

  return (
    <Chakra.Box color="white" bg="grey.400" {...props}>
      <Chakra.Flex
        justify="space-between"
        flexDir={{ base: "column", xl: "row" }}
        pt={{ base: "47px", xl: "95px" }}
        pb={{ base: "68px", xl: "100px" }}
        w="100%"
        maxW="1600px"
        px={{ base: "25px", xl: "100px" }}
        m="auto"
      >
        <Chakra.Flex flexDir={{ base: "column", xl: "row" }}>
          <Chakra.Flex
            alignItems={{ base: "center", xl: "flex-start" }}
            mr={{ base: 0, xl: "86px" }}
            mb={{ base: "35px", xl: 0 }}
            justify="space-between"
            flexDir={{ base: "row", xl: "column" }}
          >
            <Chakra.Box as="span" color="#fff">
              <Logo />
            </Chakra.Box>
            <Chakra.Stack alignItems="center" direction="row" spacing="26px">
              {socialSettings.instagramAccountUrl && (
                <Chakra.Link
                  as={Link}
                  to={socialSettings.instagramAccountUrl}
                  target="_blank"
                >
                  <InstagramIcon />
                </Chakra.Link>
              )}
              {socialSettings.twitterAccountUrl && (
                <Chakra.Link
                  as={Link}
                  to={socialSettings.twitterAccountUrl}
                  target="_blank"
                >
                  <TwitterIcon />
                </Chakra.Link>
              )}
              {socialSettings.facebookPageUrl && (
                <Chakra.Link
                  as={Link}
                  to={socialSettings.facebookPageUrl}
                  target="_blank"
                >
                  <FacebookIcon />
                </Chakra.Link>
              )}
            </Chakra.Stack>
          </Chakra.Flex>
          <FadeTransition shouldChange={String(loggedIn)}>
            {loggedIn ? (
              <Chakra.Flex mb="52px" d={{ base: "flex", xl: "none" }}>
                <Button
                  mr="31px"
                  background="transparent"
                  border="1px solid #ECE0CF"
                  color="#ECE0CF"
                  onClick={logout}
                >
                  Logout
                </Button>
                <Button
                  as={Link}
                  to="/my-account"
                  background="#ECE0CF"
                  color="black"
                >
                  My Account
                </Button>
              </Chakra.Flex>
            ) : (
              <Chakra.Flex mb="52px" d={{ base: "flex", xl: "none" }}>
                <Button
                  as={Link}
                  to="/login/"
                  mr="31px"
                  bg="yellow"
                  color="black"
                >
                  Login
                </Button>
                <Button as={Link} to="/sign-up/" bg="yellow" color="black">
                  Register an account
                </Button>
              </Chakra.Flex>
            )}
          </FadeTransition>
          <Chakra.Flex
            mb={{ base: "125px", xl: 0 }}
            flexDir={{ base: "row-reverse", xl: "row" }}
            justify="space-between"
            maxW="320px"
          >
            {footerMenu.subMenus.map(({ subMenuTitle, links }, i) => (
              <Chakra.Flex key={i} flexDir="column" mr={{ base: 0, xl: "135px" }}>
                <Chakra.Box i="500">{subMenuTitle}</Chakra.Box>
                <Chakra.UnorderedList
                  spacing="12px"
                  mt="22px"
                  styleType="none"
                  ml="0"
                  flexDir="column"
                >
                  {links.map(({ link }, idx) => (
                    <Chakra.ListItem key={idx}>
                      <Chakra.Link as={Link} to={link.url}>
                        {link.title}
                      </Chakra.Link>
                    </Chakra.ListItem>
                  ))}
                </Chakra.UnorderedList>
              </Chakra.Flex>
            ))}
          </Chakra.Flex>
        </Chakra.Flex>

        <Chakra.Flex flexDir="column" justify="space-between">
          <FadeTransition shouldChange={String(loggedIn)}>
            {loggedIn ? (
              <Chakra.Flex d={{ base: "none", xl: "flex" }}>
                <Button
                  mr="31px"
                  bg="transparent"
                  outline="1px solid #ECE0CF"
                  color="#ECE0CF"
                  onClick={logout}
                >
                  Logout
                </Button>
                <Button
                  as={Link}
                  to="/my-account"
                  background="#ECE0CF"
                  color="black"
                >
                  My Account
                </Button>
              </Chakra.Flex>
            ) : (
              <Chakra.Flex d={{ base: "none", xl: "flex" }}>
                {footerMenu.buttonLeft && (
                  <Button
                    as={Link}
                    to={footerMenu.buttonLeft.url}
                    mr="31px"
                    bg="yellow"
                    color="black"
                  >
                    {footerMenu.buttonLeft.title}
                  </Button>
                )}
                {footerMenu.buttonRight && (
                  <Button
                    as={Link}
                    to={footerMenu.buttonRight.url}
                    bg="yellow"
                    color="black"
                  >
                    {footerMenu.buttonRight.title}
                  </Button>
                )}
              </Chakra.Flex>
            )}
          </FadeTransition>
          <Chakra.Box
            maxW={{ base: "none", xl: "165px" }}
            fontSize="12px"
            color="rgba(235, 235, 235, 0.5)"
          >
            © Copyright BaccPrep {new Date().getFullYear()}
            <Chakra.Box d={{ base: "inline", xl: "none" }}>
              {" "}
              |{" "}
            </Chakra.Box>{" "}
            <Chakra.Link
              as={Link}
              to="https://atollon.com.au/"
              target="_blank"
              textDecoration="none"
            >
              Design Atollon
            </Chakra.Link>
          </Chakra.Box>
        </Chakra.Flex>
      </Chakra.Flex>
    </Chakra.Box>
  );
};

export default Footer;
